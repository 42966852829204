import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LoginSchema from "./validation schema/LoginSchema";
import { Auth } from "aws-amplify";
import "../../Stylesheets/Account.css";
import HelmetMetaData from "../HelmetMetaData";
import LoginAPI from "./LoginAPI";

function LoginWordPress(props) {
    const [APIDetailsLogin, setAPIDetailsLogin] = useState({
        user: '',
        pass: '',
    })

    const [loginDetails, setLoginDetails] = useState({
        user: '',
        pass: '',
    })

    function handleChange(e) {
        const { name, value } = e.target
        setLoginDetails(prev => {
            return (
                { ...prev, [name]: value }
            )
        })
    }

    function handleSubmit(e) {
        console.log(e)
        setAPIDetailsLogin(
            {
                user: e.email,
                pass: e.password
            }
        )
    }

    async function login(data) {
        try {
            const { email, password } = data;
            await Auth.signIn(email, password)
            window.location.href = "/";
        } catch (e) {
            alert("Something went wrong, please try again.")
        }
    }

    console.log(props.isLoggedIn)
    useEffect(() => {
        if (props.isLoggedIn === true) {
            // window.location.replace("/account");
        }
    }, [props.isLoggedIn])
    return (
        <div className="account">
            {/* <Container
                className="w-50 container pt-3 pb-3 mx-auto account-form"
            > */}
            <HelmetMetaData
                title="Innov-Edu Certificate | Login"
                description="Login to your certificate account."
            ></HelmetMetaData>
            <Row style={{ width: "100%", margin: "0" }} >
                <Col md={6} sm={12}>
                    <Container id="login-container">
                        <h1>Log in to Your Account</h1>
                        <p>Log in to your account so you can continue using our platform. You can log in with your innov-edu.ca account.</p>
                        {/* <div className="wordpress-login">
                            <input type="email" placeholder="Email Address" name="user" value={loginDetails.email} onChange={handleChange} />
                            <input type="password" placeholder="Password" name="pass" value={loginDetails.pass} onChange={handleChange} />
                            <input type="submit" onClick={handleSubmit} />
                        </div> */}
                        <Formik
                            validationSchema={LoginSchema}
                            onSubmit={(data, { setSubmitting }) => {
                                setSubmitting(true);
                                // login(data);
                                // console.log("Submit: ", data);
                                handleSubmit(data);
                                setSubmitting(false);
                            }}
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                        >{({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            values,
                            touched,
                            errors,
                            isValid
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row md={1}>
                                    <Col sm={12}>
                                        <Form.Group className="auth-input">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                placeholder="Email"
                                                isValid={touched.email && !errors.email}
                                                isInvalid={!!errors.email}
                                                id="email"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12}>
                                        <Form.Group className="auth-input">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                value={values.pass}
                                                onChange={handleChange}
                                                placeholder="Password"
                                                isValid={touched.password && !errors.password}
                                                isInvalid={!!errors.password}
                                                id="password"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button
                                    disabled={isSubmitting}
                                    className="mt-3 btn-form btn-login"
                                    variant="primary"
                                    type="submit"
                                >
                                    LOG IN
                                </Button>
                                <Row md={2} style={{ padding: "0em 1em 2em 0em" }}>
                                    {/* <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Check style={{ float: "left" }} type="checkbox" label="Remember Me" />
                                        </Form.Group>
                                    </Col> */}
                                    {/* <Col> */}
                                    <div>
                                        <a style={{ float: "left" }} href="https://www.innov-edu.ca/my-account/lost-password/">Forgot Password?</a>
                                    </div>
                                    {/* </Col> */}
                                </Row>
                            </Form>
                        )}
                        </Formik>

                        <LoginAPI APIDetailsLogin={APIDetailsLogin} setUsername={props.setUsername} setIsLoggedIn={props.setIsLoggedIn} />
                    </Container>
                </Col>
                <Col id="reg-img" md={6} sm={12}>
                    <div id="register-container">
                        <h1>Don't Have an Account Yet?</h1>
                        <p>Register in a few easy steps for a quick set up!</p>
                        <Button
                            //   disabled={!isEnabled}
                            className="mt-3 btn-reg"
                            // variant="primary"
                            href="https://www.innov-edu.ca/pricing/"
                        >
                            REGISTER NOW
                        </Button>
                    </div>
                    {/* <img src="/loginBG.jpg"/> */}
                </Col>
            </Row>
            {/* </Container> */}
        </div>
    )
}

export default LoginWordPress;