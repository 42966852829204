import React, { Component, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
// import b1 from "./b3.jpg"
import "./Stylesheets/Home.css";
import { useCookies } from "react-cookie";
import HelmetMetaData from "./components/HelmetMetaData";

function Home() {
    const [show, setShow] = useState(false);
    const [cookies, setCookies] = useCookies("cookiesPolicy");
    const handleShowPopup = () => setShow(true);
    const handleClosePopup = () => {
        setCookies('cookiesPolicy', "seen", { sameSite: 'Strict', maxAge: 604800 });
        setShow(false);
    };
    const checkCookie = () => {
        if (cookies.cookiesPolicy !== "seen") {
            handleShowPopup();
        }
    };

    useEffect(() => {
        checkCookie();
    }, []);

    return (
        <div className="home">
            <HelmetMetaData
                title="Innov-Edu Certificate | Home"
                description="Welcome to our certificate page. Here you are able to explore the site and retrieve any certificates that have been issued to you. You could also create a certificate if you are a registered member."
            ></HelmetMetaData>
            <Row className="align-items-center">
                <Col md={6}>
                    <h1>Innov-Edu</h1>
                    <h1>Certificate System</h1>
                    {/* <p><span className="quote_text">Our Mission is to...</span><br/>
                    <span className="quote_text2">Augment the Global Human Capital,</span><br/>
                    <span className="quote_text">So No One is Left Behind by Innovation</span></p> */}
                    <img src="/Mission statement.png" style={{ width: "100%" }}></img>
                    <h2>Blockchain Verified Certification</h2>
                    <p>Auto-Retrieve or Auto-Verify Your Innov-Edu Course Certificate on the Blockchain!</p>

                    <Button href="/view/" id="access-btn">Access Certificate Now!</Button>
                </Col>
                <Col md={6}>
                    <Container>
                        {/* <img style={{ width: "100%", display: "block", margin: "auto" }} src="/Home Blockchain.png" /> */}
                        <video
                        className="intro_video"
                        poster="./video 1 cover.png"
                        title="Video"
                        width="100%"
                        controls
                        controlsList="nodownload"
                        src="https://innovfin-videos.s3.us-east-2.amazonaws.com/Certificate+Video.mp4"
                        autoPlay
                    />
                    </Container>
                </Col>
            </Row>
            <Row style={{margin:"2em auto"}}>
                <Col style={{ display: "flex", textAlign:"left", justifyContent:"center", alignContent:"center", flexDirection:"column"}} md={6} xs={12}>
                    <h2>Learn More About Being an Instructor</h2>
                    <p>If you are an instructor (or considering to become an instructor) on Innov-Edu, and you would like to learn more about interacting with our Blockchain Certification platform, please watch the following video.</p>
                </Col>
                {/* <Col md={6} xs={12}>
                    <h2 style={{textAlign:"center"}}>Metamask Tutorial</h2>
                    <video
                        className="intro_video"
                        poster="./metamask tut.png"
                        title="Video"
                        width="100%"
                        controls
                        controlsList="nodownload"
                        src="https://innovfin-videos.s3.us-east-2.amazonaws.com/Loyalty+Platform+Metamask+Tutorial.mp4"
                        // autoPlay
                    />
                </Col> */}
                {/* <Col md={6} xs={12}>
                    <h2 style={{textAlign:"center"}}>...For Instructors</h2>
                    <video
                        className="intro_video"
                        poster="./instructor.png"
                        title="Video"
                        width="100%"
                        controls
                        controlsList="nodownload"
                        src="https://innovfin-videos.s3.us-east-2.amazonaws.com/Certificate+Instructors.mp4"
                        // autoPlay
                    />
                </Col> */}
                <Col md={6} xs={12}>
                    {/* <h2 style={{textAlign:"center"}}>...For Learners</h2> */}
                    <video
                        className="intro_video"
                        poster="./video 2 cover.png"
                        title="Video"
                        width="100%"
                        controls
                        controlsList="nodownload"
                        src="https://innovfin-videos.s3.us-east-2.amazonaws.com/Certificate+Video+2+FINAL.mp4"
                        // autoPlay
                    />
                </Col>
            </Row>
            <Modal
                animation={false}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                dialogClassName="popup-dialog-size">
                <Modal.Header>
                    <Modal.Title>
                        Cookies Policy
                    </Modal.Title>
                    {/* <button className="btn-link" onClick={handleClosePopup}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="black" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button> */}
                </Modal.Header>
                <Modal.Body>
                    A cookie is a small file that our site stores on your device when you visit. These files are used to keep track of information such as what popups you have seen so that you do not see them every time you visit our website. Other uses of cookies on our site includes, but are not limited to, handling widgets for YouTube and other social media sites as well as Google and Facebook services.
                    <br /><br />
                    An example of Cookie usage is when the user acknowledges the use of our cookies by pressing "understood", this dialog will not show up again until the cookie has expired or if you have manually cleared cookies from your browser.
                    <br /><br />
                    By accessing our website you agree to the use of cookies for the purposes stated here.
                </Modal.Body>
                <Modal.Footer className="popup-innovEdu-footer">
                    <Button onClick={handleClosePopup}>Understood</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Home;