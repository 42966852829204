import React, { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { Button, Row, Container } from "react-bootstrap";
import "../../Stylesheets/Account.css";
import HelmetMetaData from "../HelmetMetaData";

function account(props) {
    const [pageText, setPageText] = useState('You are not logged in.');
    const [email, setEmail] = useState('');
    const [regDate, setRegDate] = useState('');
    const [role, setRole] = useState('');

    function signOut() {
        localStorage.removeItem("jwt");
        window.location.replace("/");
    }

    useEffect(() => {
        console.log(props.userData)
        if (props.userData !== undefined) {
            setEmail(`${props.userData['data']['user']['user_email']}`)
            setRegDate(`${props.userData['data']['user']['user_registered']}`)
            setPageText(`${props.userData['data']['user']['user_login']}'s User Profile`)
            setRole(`${props.userData['data']['roles']}`)
        }
    }, [props.userData])

    return (
        <div className="account">
            <HelmetMetaData
                title="Innov-Edu Certificate | Account"
                description="View the details of your certificate account."
            ></HelmetMetaData>
            <Container className="account-details">
                <h1>{pageText}</h1>
                {props.isLoggedIn ?
                    <>
                        <Row md={2}>
                            <div>
                                <h2>Registed Date</h2>
                                <p>{regDate}</p>
                            </div>
                            <div>
                                <h2>Email Used</h2>
                                <p>{email}</p>
                            </div>
                            {/* <div>
                                <h2>Role</h2>
                                <p>{role}</p>
                                {console.log("role", typeof props.userData['data']['roles'])}
                            </div> */}
                        </Row>
                        <Button onClick={() => signOut()}>Sign Out</Button>
                    </>
                    : <p>If you are logged in please wait for the site to fetch your account details from the innov-edu.ca site.</p>
                }
            </Container>
        </div>
    )
}

export default account;