import web3 from "./web3";

export const address = "0xd7516DB4E7b86130E72070ACce844A59AfeC4F79";

export const abi = [
    {
        "constant": false,
        "inputs": [
            {
                "name": "fname",
                "type": "string"
            },
            {
                "name": "lname",
                "type": "string"
            },
            {
                "name": "course",
                "type": "string"
            },
            {
                "name": "colname",
                "type": "string"
            },
            {
                "name": "id",
                "type": "int256"
            },
            {
                "name": "email",
                "type": "string"
            },
            {
                "name": "saddress",
                "type": "string"
            },
            {
                "name": "coladdress",
                "type": "string"
            },
            {
                "name": "date",
                "type": "string"
            }
        ],
        "name": "addcert",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "col",
                "type": "address"
            }
        ],
        "name": "checkcoll",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "viewcert",
        "outputs": [
            {
                "name": "fname",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "coladd",
                "type": "address"
            },
            {
                "name": "name",
                "type": "string"
            }
        ],
        "name": "addCollege",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "name": "name",
                "type": "string"
            }
        ],
        "name": "coll_added",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "name": "fname",
                "type": "string"
            },
            {
                "indexed": false,
                "name": "lname",
                "type": "string"
            },
            {
                "indexed": false,
                "name": "course",
                "type": "string"
            },
            {
                "indexed": false,
                "name": "colname",
                "type": "string"
            }
        ],
        "name": "certadded",
        "type": "event"
    }
];

export default new web3.eth.Contract(abi, address);
