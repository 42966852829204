import React, { Component, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import "../certstyle.css";
import printJs from "print-js";
import web3 from "../web3";
import { jsPDF } from "jspdf";
import pdfobject from "pdfobject";
import { Auth } from "aws-amplify";
import HelmetMetaData from "./HelmetMetaData";

var doc = new jsPDF({
  orientation: "landscape"
})

function createPDF(name, course, saddress, txhash) {
  var img = new Image;
  img.src = "/cert.png"
  doc.addImage(img, "png", 0, 0, 297, 210);
  //name
  doc.setFontSize(40)
  doc.setFont("Lato-Regular", "normal");
  doc.text(name, (doc.internal.pageSize.width / 2) + 5, 60, null, null, "center");
  //course
  doc.setFontSize(30)
  doc.setFont("Lato-Black", "bold");
  doc.text(course, (doc.internal.pageSize.width / 2) + 5, 95, null, null, "center");

  doc.setFontSize(12)
  doc.setFont("Lato-Regular", "normal");
  doc.text(saddress, (doc.internal.pageSize.width / 2) + 22, 189, null, null, "center");

  doc.setFont("Lato-Regular", "normal");
  doc.text(txhash, (doc.internal.pageSize.width / 2) + 45, 197, null, null, "center");
  pdfobject.embed(doc.output('datauristring') + "#toolbar=0", "#cert", { forcePDFJS: true })
}

class Certificate extends Component {

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  // componentDidMount() {
  //   document.addEventListener('contextmenu', (e) => {
  //     e.preventDefault();
  //   });
  // };

  state = {
    fname: "",
    lname: "",
    fullname: "",
    course: "",
    colname: "",
    id: 0,
    email: "",
    saddress: "",
    coladdress: "",
    date: "",
    txhash: "",
    loaded: false,
    currAccount: "",
    admin: false
  };

  getAccount(callback) {
    web3.eth.getAccounts((error, result) => {
      if (error) {
        console.log(error);
      } else {
        callback(result);
      }
    })
  }

  getTransDetails = data => {
    let account = "";

    this.getAccount(function (result) {
      account = result[0];
      // console.log("account ", result[0], account)
    });

    web3.eth.getTransaction(this.state.txhash, (err, tx) => {
      try {
        // console.log("data", data)
        let tx_data = tx.input;
        let input_data = "0x" + tx_data.slice(10);
        let params = web3.eth.abi.decodeParameters(["string", "string", "string", "string", "int256", "string", "string", "string", "string"], input_data);
        // console.log(tx.to);
        // console.log(params);
        console.log("arams ", params)
        this.setState({
          fname: params[0],
          lname: params[1],
          fullname: params[0] + " " + params[1],
          course: params[2],
          colname: params[3],
          id: params[4],
          email: params[5],
          loaded: true,
          saddress: params[6],
          coladdress: params[7],
          date: params[8],
          currAccount: account
        });
        const user = Auth.currentAuthenticatedUser().then(data => {
          // console.log("auth data", data.signInUserSession.accessToken.payload["cognito:groups"])
          const groups = data.signInUserSession.accessToken.payload["cognito:groups"]
          if (groups.includes("Admin")) {
            //if so, the user is authenticated
            this.setState({ ...this.state, admin: true });
          } else {
            //else, user is not authenticated
            this.setState({ ...this.state, admin: false });
          }
        })
        if(this.props.user != undefined){
          if(this.props.user["data"]["roles"].includes("administrator")){
            this.setState({...this.state, admin: true})
          } else {
            this.setState({ ...this.state, admin: false });
          }
        }
        // const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        // if (groups.includes("Admin")) {
        //   //if so, the user is authenticated
        //   setState({ ...state, admin: true });
        // } else {
        //   //else, user is not authenticated
        //   setState({ ...state, admin: false });
        // }
        createPDF(this.state.fullname, this.state.course, this.state.saddress, this.state.txhash);
      } catch (e) {
        alert("Transaction Hash could not be found. Please try again or check your metamask settings.")
        console.log(e)
      }
    });
  }
  // state = {};
  onclickprint = event => {
    event.preventDefault();
    //doc.save(this.props.sname + "_" + this.props.course +".pdf");
    //printJs("printcertificate", "html");
  };

  render() {
    console.log("state", this.state, this.props)
    return (
      <div className="w-100 pc " id="top">
        <HelmetMetaData
          title="Innov-Edu Certificate | Retrieve Certificate"
          description="You can retrieve a PDF certificate for your officially issued certificate here. Providing the transaction hash that was given to you, this webpage retrieves the data from the blockchain and prints it out as a PDF for you to download. Only owners are allowed to download their certificates."
        ></HelmetMetaData>
        <Container id="certContainer">
          <h1>Retrieve Certificate from the Blockchain.</h1>
          <Form>
            <Form.Group>
              <Form.Control
                type="text"
                name="txhash"
                value={this.state.txhash}
                onChange={this.handleChange}
                placeholder="Transaction Hash"
              />
            </Form.Group>
            <Button
              className="mt-3"
              variant="primary"
              // type="submit"
              onClick={() => {
                this.getTransDetails();
              }}
            >
              Get Certificate Details
            </Button>
          </Form>
          {!this.state.loaded ? null
            :
            <Container id="certFull">
              <div id="cert-contain">
                <div id="embed-cover"></div>
                <div id="cert"></div>
              </div>

              {(this.state.saddress === this.state.currAccount || this.state.admin == true) ?
                (<Button
                  className="btn"
                  onClick={() => {
                    doc.save(this.state.fname + "-" + this.state.course + "-certificate.pdf");
                  }}
                  variant="success"
                // type="submit"
                >
                  Save to PDF
                </Button>)
                :
                (<div>
                  <Button
                    disabled={true}
                    className="btn"
                    onClick={() => {
                      doc.save(this.state.fname + "-" + this.state.course + "-certificate.pdf");
                    }}
                    variant="success"
                  // type="submit"
                  >
                    Save to PDF
                  </Button>
                </div>)
              }

            </Container>
          }
          <p style={{ color: "white", margin: "1em 0em" }}>The owner of the certificate will be able to download a PDF of this certificate.</p>
        </Container>
      </div>
    );
  }
}

export default Certificate;
