import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import SignupConfirmSchema from "./validation schema/SignupConfirmSchema";
import { Auth } from 'aws-amplify';
import HelmetMetaData from "../HelmetMetaData";

function SignupConfirm() {
    async function confirmSignUp(data) {
        try {
            const { email, authCode } = data;
            await Auth.confirmSignUp(email, authCode);
            window.location.href = "/login/";
        } catch (e) {
            console.log("error ", e);
            alert("An error has occured please try again.")
        }
    }
    return (
        <div className="account">
            <HelmetMetaData
                title="Innov-Edu Cerrtificate | Signup Confirm"
                description="Confirm your account signup."
            ></HelmetMetaData>
            <Row style={{width:"100%", margin:"0"}}>
                <Col md={6} sm={12}>
                    <Container id="login-container">
                        <h1>Confirm your Account</h1>
                        <Formik
                            validationSchema={SignupConfirmSchema}
                            onSubmit={(data, { setSubmitting }) => {
                                setSubmitting(true);
                                confirmSignUp(data);
                                // console.log("Submit: ", data);
                                setSubmitting(false);
                            }}
                            initialValues={{
                                email: '',
                                authCode: '',
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                isSubmitting,
                                values,
                                touched,
                                errors,
                                isValid
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row md={1}>
                                        <Col sm={12}>
                                        <Form.Group className="auth-input">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                placeholder="Email"
                                                isValid={touched.email && !errors.email}
                                                isInvalid={!!errors.email}
                                                id="email"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        </Col>
                                        <Col sm={12}>
                                        <Form.Group className="auth-input">
                                            <Form.Label>Confirmation Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="authCode"
                                                value={values.authCode}
                                                onChange={handleChange}
                                                placeholder="Confirmation Code"
                                                isValid={touched.authCode && !errors.authCode}
                                                isInvalid={!!errors.authCode}
                                                id="authCode"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.authCode}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button
                                        disabled={isSubmitting}
                                        className="mt-3 btn-form btn-login"
                                        variant="primary"
                                        type="submit"
                                    >
                                        Confirm
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Container>
                </Col>
                <Col id="reg-img" md={6} sm={12}>
                    <div id="register-container">
                        <h1>Already confirmed your account?</h1>
                        <p>Go back to the log in page to log into your account and get started!</p>
                        <Button
                            //   disabled={!isEnabled}
                            className="mt-3 btn-reg"
                            // variant="primary"
                            href="/login/"
                        >
                            LOG IN NOW
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SignupConfirm;