import React from "react"
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../Stylesheets/Footer.css"

function Footer() {
    return (
        <div className="footer">
            <Nav className="mr-auto ml-auto nav-fill justify-content-center" style={{width:"60%"}}>
                <Nav.Item className="mt-2 ml-2 mr-2 ">
                    <Link to="/">Home</Link>
                </Nav.Item>
                <Nav.Item className="mt-2 ml-2 mr-3">
                    <a href="https://www.innov-edu.ca/">Register for Courses</a>
                </Nav.Item>
                <Nav.Item className="mt-2 ml-2 mr-3">
                    <a href="https://loyalty.innov-edu.ca/">Earn Points/Tokens</a>
                </Nav.Item>
                <Nav.Item className="mt-2 ml-2 mr-3">
                    <a href="https://www.marketplace.innov-edu.ca/">NFT Marketplace</a>
                </Nav.Item>
                <Nav.Item className="mt-2 ml-2 mr-3">
                    <a href="/terms-and-conditions/">Terms &amp; Conditions</a>
                    {/* <Link to="/terms-and-conditions/">Terms &amp; Conditions</Link> */}
                </Nav.Item>
                <Nav.Item className="mt-2 ml-2 mr-3">
                    <a href="/privacy-policy/">Privacy Policy</a>
                    {/* <Link to="/privacy-policy/">Privacy Policy</Link> */}
                </Nav.Item>
                <Nav.Item className="mt-2 mr-3">
                    <a href="https://www.innov-edu.ca/contact-us/">Contact Us</a>
                </Nav.Item>
                <Nav.Item className="mt-2 mr-3">
                    <a href="/sitemap.xml">Sitemap</a>
                </Nav.Item>
            </Nav>
            <p id="copyright">Copyright©2021-2022 InnovFin Consulting Inc. All rights reserved</p>
            <img className="innovfin-logo" src="/innov-edu-final.png"></img>
            <p className="footer_quote">Augmenting the Global Human Capital, So No One is Left Behind by Innovation</p>
        </div>
    )
}

export default Footer;