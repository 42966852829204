import React, { Component } from "react";
import { Container } from "react-bootstrap";
import hiw from "../hiw.jpg";
import HelmetMetaData from "./HelmetMetaData";

class Intro extends Component {
  state = {};
  render() {
    return (
      <Container>
        <HelmetMetaData
        title="Innov-Edu Certificate | How It Works"
        description="A diagram to display how our certificate system works. This simple diagram is an introduction to our system and helps users get a better understanding of how the certificate system works with the blockchain."
        ></HelmetMetaData>
        <img
          src={hiw}
          style={{ marginTop: "5px" }}
          className="img-fluid"
          alt="Responsive image"
        />
      </Container>
    );
  }
}

export default Intro;
