import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import b1 from "../b3.jpg";
import CertFound from "./certfound";
import web3 from "../web3";
import HelmetMetaData from "./HelmetMetaData";

class Getcert extends Component {
  getNetwork() {
    web3.eth.net.getNetworkType().then(
      response => (this.setState({
        network: response
      }))
    )
  }
  canBeSubmittedid() {
    const id = this.state.id;
    return id.length > 0;
  }
  canBeSubmittedtxh() {
    const txh = this.state.txh;
    return txh.length > 0;
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  getcertificate = event => {
    event.preventDefault();
    this.props.getcertificate(this.state);
    //console.log(this);
  };

  gettransaction = event => {
    event.preventDefault();
    let url = ""
    // if (this.state.network === "kovan") {
    //   console.log("kovan")
    //   url = "https://kovan.etherscan.io/tx/" + this.state.txh;
    // } else {
    //   url = "https://ropsten.etherscan.io/tx/" + this.state.txh;
    // }
    switch (this.props.chain) {
      case "Mumbai Testnet":
        url = "https://mumbai.polygonscan.com/tx/" + this.state.txh;
        break;
      case "Kovan Testnet":
        url = "https://ropsten.etherscan.io/tx/" + this.state.txh;
        break;
      case "Polygon Mainnet":
        url = "https://polygonscan.com/tx/" + this.state.txh;
        break;
    }
    window.open(url);
    console.log(url);
  };

  state = {
    id: "",
    txh: "",
    network: "ropsten"
  };
  render() {
    const isEnabledid = this.canBeSubmittedid();
    const isEnabledtxh = this.canBeSubmittedtxh();
    return (

      <div
        style={{
          //backgroundImage: `url(${b1})`,
          background: `url(${b1}) no-repeat `,
          backgroundSize: "cover",
          border: "1px solid black",
          height: "100vh",
          width: "100%"
        }}
        className="container-fluid "
      >
        <HelmetMetaData
          title="Innov-Edu Certificate | Verify"
          description="On this page, you can verify a certificate that has been issued to you on the blockchain. Using the transaction hash that was rprovided to you, you can enter the hash here and view the transaction on the blockchain explorer."
        >
        </HelmetMetaData>
        <div className=" mx-auto w-50">
          <h1
            style={{
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: "bold"
            }}
            className=" mt-3 mb-4"
          >
            Verify the Certificate
          </h1>

          {/* <Form
            className="px-3 pt-4"
            onSubmit={this.getcertificate}
            style={{ background: "rgba(255,255,255,0.5)" }}
          >
            <Form.Group>
              <Form.Control
                type="text"
                name="id"
                value={this.state.id}
                onChange={this.handleChange}
                placeholder="Enter Certificate ID"
              />
            </Form.Group>
            <Button
              disabled={!isEnabledid}
              className="mt-2 mb-3"
              variant="primary"
              type="submit"
            >
              Get certificate
            </Button>
          </Form>
          {this.props.yes ? <CertFound details={this.props.details} /> : null}
         */}
          <hr
            className="mt-5 "
            style={{ color: "red", backgroundColor: "red", height: 5 }}
          />
        </div>

        <div className=" mx-auto w-50 mb-5">
          <h1
            style={{
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: "bold"
            }}
            className="mt-5 mb-4"
          >
            Verify the Transaction details
          </h1>
          {this.props.connected ?
            <p style={{ color: "white", fontFamily: "Montserrat", }}>
              Note that your metamask is connected to {this.props.chain}.
              <br/>
              Incorrect Network? Change your metamask connection and refresh.
            </p>
            : null}
          <Form
            className="px-3 pt-4"
            onSubmit={this.gettransaction}
            style={{
              marginBottom: "57px",
              background: "rgba(255,255,255,0.5)"
            }}
          >
            <Form.Group>
              <Form.Control
                type="text"
                name="txh"
                value={this.state.txh}
                onChange={this.handleChange}
                placeholder="Enter Transaction hash provided on Certificate"
              />
            </Form.Group>
            <Button
              disabled={!isEnabledtxh}
              className="mt-2 mb-3"
              variant="primary"
              type="submit"
              style={{ margin: "0em 1em" }}
              onClick={() => {
                this.setState({ network: "kovan" })
              }}
            >
              Verify on the Blockchain
            </Button>
            {/* <Button
              disabled={!isEnabledtxh}
              className="mt-2 mb-3"
              variant="primary"
              type="submit"
              style={{ margin: "0em 1em" }}
              onClick={() => {
                this.setState({ network: "ropsten" })
              }}
            >
              Verify on Ropsten
            </Button> */}
          </Form>
        </div>
      </div >
    );
  }
}

export default Getcert;
