import React, { Component, useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col } from "react-bootstrap";
import { a } from "react-router-dom";
import "../Stylesheets/Navbar.css";
import { Auth } from 'aws-amplify';
// class Navhead extends Component {
// state = {};
// render() {
function Navhead(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // console.log(user);
      setLoggedIn(true);
    } catch (e) {
      console.log('error: ', e)
    }
  }
  useEffect(() => {
    checkUser();
  }, [])
  return (
    <Navbar
      className="bar mr-auto ml-auto justify-content-center"
      style={{width:"70%"}}
      // bg="dark"
      // variant="dark"
      expand="lg"
      >
      <Navbar.Brand mb={0} href="/">
        {/* InnovFin */}
        <img id="brand-logo" src="/InnovEdu Logo Final.png"/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav style={{width:"100%"}} className="mr-auto ml-auto nav-fill justify-content-center">
          <Nav.Item className="mt-1 mr-3 m1-3 top-nav">
          </Nav.Item>
          <Nav.Item className="mt-2 mr-1 ml-1 top-nav">
            <a href="/">Home</a>
          </Nav.Item>
          <Nav.Item className="mt-2 ml-1 mr-1 top-nav">
            <a href="/register/">Register</a>
          </Nav.Item>
          <Nav.Item className="mt-2 ml-1 mr-1 top-nav">
            <a href="/intro/">How it works</a>
          </Nav.Item>
          <Nav.Item className="mt-2 ml-1 mr-1 top-nav">
            <a href="/verify/">Verify</a>
          </Nav.Item>
          <Nav.Item className="mt-2 mr-1 ml-1 top-nav">
            <a href="/view/">View Certificate</a>
          </Nav.Item>
          <Nav.Item className="mt-2 mr-3 top-nav">
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
      {!props.isLoggedIn ? <Button href="/login/" id="login-btn">Log in</Button>
        : <Button href="/account/" id="login-btn">Account</Button>}
    </Navbar>
  );
  // }
}

export default Navhead;
