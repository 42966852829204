import React, { Component, useEffect, useState } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import web3 from "./web3.js";
import "./App.css";
import Navhead from "./components/navbar";
import Forms from "./components/registration";
import Getcert from "./components/getcert";
import Reg from "./components/reg";
import Intro from "./components/intro";
import certcontract from "./config.js";
import certcontractMumbai from "./configPolygon.js";
import certcontractPolygon from "./configMainnet.js";
import Certificate from "./components/certificate";
import Home from "./Home.js";
import { jsPDF } from "jspdf";
import pdfobject from "pdfobject";
import Login from "./components/Account/Login.jsx";
import Signup from "./components/Account/Signup.jsx";
import SignupConfirm from "./components/Account/SignupConfirm.jsx";
import Account from "./components/Account/Account.jsx";
import ForgotPassword from "./components/Account/ForgotPassword.jsx";
import ForgotPasswordConfirm from "./components/Account/ForgotPasswordConfirm.jsx";
import Footer from "./components/Footer.jsx";
import TermsAndCondition from "./components/TermsAndConditions.jsx";
import PrivacyPolicy from "./components/PrivacyPolicy.jsx";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { Switch } from "react-router-dom";
import LoginWordPress from "./components/Account/LoginWordPress.jsx";
import Web3 from "web3";

var doc = new jsPDF({
    orientation: "landscape"
})

function createPDF(name, course, saddress, txhash) {
    var img = new Image;
    img.src = "/cert.png"
    doc.addImage(img, "png", 0, 0, 297, 210);
    //name
    doc.setFontSize(40)
    doc.setFont("Lato-Regular", "normal");
    doc.text(name, (doc.internal.pageSize.width / 2) + 5, 60, null, null, "center");
    //course
    doc.setFontSize(30)
    doc.setFont("Lato-Black", "bold");
    doc.text(course, (doc.internal.pageSize.width / 2) + 5, 95, null, null, "center");

    doc.setFontSize(12)
    doc.setFont("Lato-Regular", "normal");
    doc.text(saddress, (doc.internal.pageSize.width / 2) + 22, 189, null, null, "center");

    doc.setFont("Lato-Regular", "normal");
    doc.text(txhash, (doc.internal.pageSize.width / 2) + 45, 197, null, null, "center");
    pdfobject.embed(doc.output('datauristring') + "#toolbar=0", "#cert-reg", { forcePDFJS: true })

    var txhash_txt = `<p>Here is the transaction hash: ${txhash}</p>`
    document.getElementById("txhash-text").innerHTML = txhash_txt;
}

const App = () => {
    const [account, setAccount] = useState("");
    const [name, setName] = useState("");
    const [course, setCourse] = useState("");
    const [txh, setTxh] = useState("");
    const [id, setId] = useState("");
    const [studentAddress, setStudentAddress] = useState("");
    const [colname, setColname] = useState("");
    const [colAddress, setColAddress] = useState("");
    const [dateIssuled, setDateIssued] = useState("");
    const [output, setOutput] = useState("");
    const [tofound, setTofound] = useState("");
    const [username, setUsername] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [userData, setUserData] = useState();
    const [chain, setChain] = useState("");

    async function loadBlockchainData() {
        //added line 30
        await window.ethereum.enable()
        const accounts = await web3.eth.getAccounts();
        web3.eth.defaultAccount = accounts[0];
        switch (window.web3.currentProvider.chainId) {
            case "0x89":
                setChain("Polygon Mainnet");
                break;
            case "0x13881":
                setChain("Mumbai Testnet");
                break;
            case "0x1":
                setChain("Ethereum Mainnet");
                break;
            case "0x2a":
                setChain("Kovan Testnet");
                break;
            case "0x3":
                setChain("Ropsten Testnet");
                break;
            case "0x4":
                setChain("Rinkeby Testnet");
                break;
            case "0x5":
                setChain("Goerli Testnet");
                break;
            default:
                setChain(window.web3.currentProvider.chainId)
                break;
        }
        //console.log(certcontract);
        console.log(accounts);
        console.log("acc", accounts[0]);
        setAccount(accounts[0]);
    }

    const save = data => {
        doc.save(data.fname + "-" + data.course + "-certificate.pdf");
    }
    const add = data => {
        //const certificates = new this.state.web3.eth.Contract(abi, address);
        //console.log(data.fname, data.course, data.email);
        //var name = data.fname + " " + data.lname;
        switch (chain){
            case "Kovan Testnet":
                certcontract.methods.addcert(data.fname, data.lname, data.course, data.colname, data.id, data.email, data.studentaddress, data.coladdress, data.dateissued).send(
                    {
                        from: account,
                        gas: 500000
                    },
                    (error, result) => {
                        if (error) console.log("error " + error);
                        else {
                            setName(data.fname + " " + data.lname);
                            setCourse(data.course + " " + "course");
                            setStudentAddress(data.studentaddress);
                            setColname(data.colname);
                            setColAddress(data.coladdress);
                            setDateIssued(data.dateissued);
                            setId(data.id);
                            setTxh(result);
                            // console.log(result);
                            createPDF(data.fname + " " + data.lname, data.course, data.studentaddress, result);
    
                        }
                    }
                );
                break;
            case "Mumbai Testnet":
                certcontractMumbai.methods.addcert(data.fname, data.lname, data.course, data.colname, data.id, data.email, data.studentaddress, data.coladdress, data.dateissued).send(
                    {
                        from: account,
                        gas: 500000
                    },
                    (error, result) => {
                        if (error) console.log("error " + error);
                        else {
                            setName(data.fname + " " + data.lname);
                            setCourse(data.course + " " + "course");
                            setStudentAddress(data.studentaddress);
                            setColname(data.colname);
                            setColAddress(data.coladdress);
                            setDateIssued(data.dateissued);
                            setId(data.id);
                            setTxh(result);
                            // console.log(result);
                            createPDF(data.fname + " " + data.lname, data.course, data.studentaddress, result);
    
                        }
                    }
                );
                break;
            case "Polygon Mainnet":
                certcontractPolygon.methods.addcert(data.fname, data.lname, data.course, data.colname, data.id, data.email, data.studentaddress, data.coladdress, data.dateissued).send(
                    {
                        from: account,
                        gas: 500000
                    },
                    (error, result) => {
                        if (error) console.log("error " + error);
                        else {
                            setName(data.fname + " " + data.lname);
                            setCourse(data.course + " " + "course");
                            setStudentAddress(data.studentaddress);
                            setColname(data.colname);
                            setColAddress(data.coladdress);
                            setDateIssued(data.dateissued);
                            setId(data.id);
                            setTxh(result);
                            // console.log(result);
                            createPDF(data.fname + " " + data.lname, data.course, data.studentaddress, result);
    
                        }
                    }
                );
                break
        }
    };

    useEffect(() => {
        console.log('mounted');
        ReactGA.initialize("G-30QL1JNH57");
        console.log(window.location.pathname + window.location.search)
        ReactGA.pageview(window.location.pathname + window.location.search);
        loadBlockchainData();
        let userJWT = localStorage.getItem('jwt') || '';
        // console.log(userJWT);
        if (userJWT.length > 0) {
            let url = `https://www.innov-edu.ca/?rest_route=/simple-jwt-login/v1/auth/validate&JWT=${userJWT}`;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if (data['success'] === true) {
                        console.log("user is persisted")
                        setUserData(data)
                        setIsLoggedIn(true)
                        setUsername(data['data']['user']['user_login'])
                    } else {
                        console.log(data, "persist failed")
                    }
                })
        }
    }, [])

    useEffect(() => {
        console.log("Net: ", chain);
    }, [chain])

    return (
        <div className="App">
            {/* <Router> */}
            <Navhead isLoggedIn={isLoggedIn} />
            <Switch>
                <Route
                    path="/"
                    exact
                    component={() => <Home />}
                />
                <Route
                    path="/register/"
                    exact
                    render={() => <Forms addcertificate={add} save={save} user={userData} />}
                />
                <Route
                    path="/verify/"
                    render={() => (
                        <Getcert
                            // getcertificate={get}
                            yes={tofound}
                            details={output}
                            chain={chain}
                            connected={window.web3.currentProvider.isMetaMask}
                        />
                    )}
                />
                <Route
                    path="/view/"
                    component={() => (
                        <Certificate
                            sname={name}
                            course={course}
                            txh={txh}
                            id={id}
                            studentaddress={studentAddress}
                            chain={chain}
                            user={userData}
                        />
                    )}
                />
                <Route path="/intro/" component={Intro} />
                {/* <Route path="/reg/" component={Reg} /> */}
                <Route path="/account/" component={() => <Account isLoggedIn={isLoggedIn} userData={userData} />} />
                {/* <Route path="/login/" component={() => <Login />} /> */}
                <Route path="/login/" component={() => <LoginWordPress />} />
                <Route path="/reset/" component={() => <ForgotPassword />} />
                <Route path="/confirm-reset/" component={() => <ForgotPasswordConfirm />} />
                <Route path="/signup/" component={() => <Signup />} />
                <Route path="/signup-confirm/" component={() => <SignupConfirm />} />
                <Route path="/terms-and-conditions/" component={() => <TermsAndCondition />} />
                <Route path="/privacy-policy/" component={() => <PrivacyPolicy />} />
            </Switch>
            <Footer />
            {/* </Router> */}
        </div>
    )
}

export default App;